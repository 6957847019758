import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import clsx from 'clsx';

import Appointment, { status } from '@spike/appointment-model';

import { AppointmentOptions } from 'components/UI';
import { BookingOptions } from 'components/UI';

import { getUndoStatus } from 'utils/BookingUtils';

interface BookingOptionButtonProps {
    isDetail?: boolean;
    appointment: Appointment;
    isUndo?: boolean;
    singleBooking?: boolean;
    className?: string;
    withoutReschedule?: boolean;
    //
    onRebook?: () => void;
    onBook: () => void;
    onShowReport: (appointmentId: number, businessAreaId: string) => void;
    onCancel: (appointmentId: number) => void;
    onNoShow: (appointmentId: number) => void;
    onShowInvoice: (appointmentId: number) => void;
    onRescheduleAppointment: (appointmentId: number) => void;
    onRescheduleBooking: (bookingId: number) => void;
    onCancelBooking: (bookingId: number) => void;
    onUndo: (appointmentId: number, statusId: number) => void;
    onShowAddReport?: (
        appointmentId: number,
        businessAreaId: string,
        clientId: number
    ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {},
        typoHeader: {
            fontSize: '30px',
            fontWeight: 600
        },
        boxActions: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        gridButton: {
            paddingLeft: '10px',
            paddingTop: '5px'
        }
    })
);

export const BookingOptionButton: FunctionComponent<
    BookingOptionButtonProps
> = props => {
    const classes = useStyles();

    const isOkToUndo = () => {
        return !(
            props.appointment.status.id === status.DECLINED ||
            props.appointment.status.id === status.CONFIRMED ||
            props.appointment.status.id === status.CANCELLED ||
            props.appointment.status.id === status.COMPLETED ||
            props.appointment.status.id ===
                status.WAITING_PAYMENT_CONFIRMATION ||
            props.appointment.status.id === status.PAYMENT ||
            props.appointment.status.id === status.NO_SHOW
        );
    };

    return (
        <Box className={clsx(classes.container, props.className)}>
            {props.appointment.status.id === status.BOOKED ? (
                <BookingOptions
                    isDetail={props.isDetail}
                    withoutReschedule={props.withoutReschedule}
                    singleBooking={props.singleBooking}
                    onRebook={() => props.onRebook && props.onRebook()}
                    onReschedule={() =>
                        props.onRescheduleBooking(props.appointment.bookingId)
                    }
                    onCancel={() =>
                        props.onCancelBooking(props.appointment.bookingId)
                    }
                    onNoShow={() => props.onNoShow(props.appointment.id!)}
                />
            ) : (
                <AppointmentOptions
                    isDetail={props.isDetail}
                    appointment={props.appointment!}
                    singleBooking={props.singleBooking}
                    showUndo={isOkToUndo()}
                    withoutReschedule={props.withoutReschedule}
                    onBook={() => props.onBook()}
                    onRebook={() => props.onRebook && props.onRebook()}
                    onReschedule={() =>
                        props.onRescheduleAppointment(props.appointment.id!)
                    }
                    onCancel={() => props.onCancel(props.appointment.id!)}
                    onNoShow={() => props.onNoShow(props.appointment.id!)}
                    onShowReport={() =>
                        props.onShowReport(
                            props.appointment.id!,
                            props.appointment!.services[0].businessArea.id
                        )
                    }
                    onShowInvoice={() =>
                        props.onShowInvoice(props.appointment.id!)
                    }
                    onUndo={() =>
                        props.onUndo(
                            props.appointment.id!,
                            getUndoStatus(props.appointment.status.id)
                        )
                    }
                    onShowAddReport={() =>
                        props.onShowAddReport &&
                        props.onShowAddReport(
                            props.appointment.id!,
                            props.appointment!.services[0].businessArea.id,
                            props.appointment!.customer.id
                        )
                    }
                />
            )}
        </Box>
    );
};

export default BookingOptionButton;
