import {
    faCalendarAlt,
    faMemoCircleCheck,
    faBellConcierge
} from '@fortawesome/pro-light-svg-icons';
import { faCircleXmark, faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import {
    makeStyles,
    Theme,
    createStyles,
    Box,
    Grid,
    Typography
} from '@material-ui/core';
import {
    AppointmentsStatus,
    BookingsState,
    BookingsStatus,
    getAppointmentThunk,
    getBookingThunk
} from '@spike/bookings-action';
import useNonInitialEffect from '@versiondos/hooks';
import { setNotificationBellAmount } from 'actions/notificationBell/NotificationBellActions';
import { updateThunk } from 'actions/userNotifications/userNotificationActions';
import {
    UserNotificationSubtype,
    UserNotificationType
} from 'model/UserNotifications';
import IconAvatar from 'components/UI/IconAvatar';
import QuillContent from 'components/UI/QuillContent';
import { AES } from 'crypto-js';
import { useApiClientWrapper, useTimeZone } from 'hooks';
import { UserNotification } from 'model/UserNotifications';
import moment from 'moment-timezone';
import { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store';
import Booking from '@spike/booking-model';
import NotificationRowActions from 'components/Notifications/UI/NotificationRowActions';
import { status } from '@spike/appointment-model';
import NotificationRowRequestStatus from 'components/Notifications/UI/NotificationRowRequestStatus';

interface Props {
    notification: UserNotification;
    onRedirect?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            'width': '100%',
            'cursor': 'pointer',
            'borderBottom': 'solid 1px #D4D4D4',

            '&:hover': {
                backgroundColor: '#F8F8F8'
            }
        },
        gridContainer: {
            margin: 0,
            paddingTop: '25px',
            paddingBottom: '25px',
            [theme.breakpoints.down('md')]: {
                paddingTop: '20px',
                paddingBottom: '20px'
            },
            [theme.breakpoints.down('sm')]: {
                paddingTop: '15px',
                paddingBottom: '14px'
            }
        },
        notificationMeta: {
            gap: 4,
            display: 'flex',
            flexDirection: 'column'
        },
        notificationHeader: {
            color: '#7A7A7A',
            cursor: 'pointer',
            fontSize: '13px'
        },
        notificationTitle: {
            'cursor': 'pointer !important',
            'color': '#000000',
            'fontSize': '15px',
            'padding': '0px !important',
            'fontWeight': 600,
            '&.ql-editor > *': {
                cursor: 'pointer !important'
            },
            '&.ql-editor > strong': {
                fontWeight: 600
            }
        },
        notificationDetail: {
            'cursor': 'pointer',
            'color': '#000000',
            'fontSize': '14px',
            'padding': '0px !important',
            '&.ql-editor > *': {
                cursor: 'pointer !important'
            }
        },
        dot: {
            borderRadius: '50%',
            height: '12px',
            width: '12px',
            backgroundColor: '#EAB464',
            pointerEvents: 'none',
            [theme.breakpoints.down('sm')]: {
                position: 'relative',
                top: '5px'
            }
        },
        iconAvatar: {
            [theme.breakpoints.down('sm')]: {
                width: '42px',
                height: '42px'
            }
        },
        subIcon: {
            width: '18px !important',
            height: '18px !important',
            [theme.breakpoints.down('sm')]: {
                width: '14px !important',
                height: '14px !important'
            }
        }
    })
);

// eslint-disable-next-line
const secret: string = `${process.env.REACT_APP_GLOBAL_SECRET}`;

export const NotificationRow: FunctionComponent<Props> = props => {
    const classes = useStyles(props);

    const timeZone = useTimeZone();
    const history = useHistory();
    const apiClientWrapper = useApiClientWrapper();
    const dispatch = useDispatch();

    const [redirect, setRedirect] = useState(false);
    const [booking, setBooking] = useState<Booking>();
    const [isLoading, setIsLoading] = useState(false);

    const notificationAmount = useSelector<RootState, number>(
        state => state.userNotifications.unread.length
    );

    const bookingsState = useSelector((state: RootState) => state.bookings);

    const newBookingsStatus = useSelector(
        (state: RootState) => state.newBookings.status
    );

    const appointmentIdStore = useSelector<RootState, number | undefined>(
        state => state.appointments.appointment?.id
    );

    const bookingIdStore = useSelector<RootState, number | undefined>(
        state => state.appointments.appointment?.bookingId
    );

    const appointmentsStatus = useSelector<RootState, AppointmentsStatus>(
        state => state.appointments.status
    );

    const bookingStatus =
        booking?.appointments[0].status.id || props.notification.bookingStatus;

    const getBooking = () => {
        dispatch(
            getBookingThunk(apiClientWrapper, props.notification.bookingId!)
        );
    };

    const openBooking = (appointmentId: number, bookingId: number) => {
        const hash = encodeURIComponent(
            AES.encrypt(
                JSON.stringify({
                    appointmendId: appointmentId,
                    bookingId: bookingId
                }),
                secret
            ).toString()
        );

        setRedirect(false);
        history.replace(`/bookings/${hash}`);
        props.onRedirect?.();
    };

    const markAsRead = () => {
        dispatch(updateThunk(props.notification.receiptId!, true, true));

        if (!props.notification.readAt) {
            dispatch(setNotificationBellAmount(notificationAmount - 1));
        }
    };

    const clickHandler = () => {
        if (props.notification.appointmentId) {
            dispatch(
                getAppointmentThunk(
                    apiClientWrapper,
                    props.notification.appointmentId
                )
            );
            setRedirect(true);
        } else if (booking?.id) {
            setRedirect(true);
            markAsRead();
            openBooking(Number(booking?.appointments[0].id), booking?.id);
        } else if (!booking && props.notification.bookingId) {
            setRedirect(true);
            getBooking();
        }
    };

    useNonInitialEffect(() => {
        if (
            appointmentsStatus === AppointmentsStatus.GetSuccess &&
            redirect === true
        ) {
            if (appointmentIdStore && bookingIdStore) {
                if (props.notification.receiptId) {
                    markAsRead();
                }

                openBooking(appointmentIdStore, bookingIdStore);
            }
        }

        if (
            bookingsState.status === BookingsStatus.GetSuccess &&
            bookingsState.booking?.id === props.notification.bookingId
        ) {
            setIsLoading(false);
            setBooking(bookingsState.booking);

            if (redirect) {
                markAsRead();
                openBooking(
                    Number(bookingsState.booking?.appointments[0].id),
                    Number(bookingsState.booking?.id)
                );
            }
        }
    }, [appointmentsStatus, bookingsState.status]);

    const subIcon =
        props.notification.type === UserNotificationType.BOOKING_REQUEST
            ? undefined
            : [UserNotificationSubtype.CONFIRMED, undefined].includes(
                  props.notification.subtype
              )
            ? faCircleCheck
            : faCircleXmark;

    const shouldShowActions =
        props.notification.type === UserNotificationType.BOOKING_REQUEST &&
        bookingStatus === status.REQUESTED;

    const shouldShowBookingStatus =
        props.notification.type === UserNotificationType.BOOKING_REQUEST &&
        bookingStatus !== status.REQUESTED;

    return (
        <>
            <Box className={classes.container} onClick={clickHandler}>
                <Grid
                    container
                    justifyContent={'flex-end'}
                    className={classes.gridContainer}
                    spacing={1}
                >
                    <Grid item xs={2}>
                        <Grid container direction="row-reverse">
                            <IconAvatar
                                className={classes.iconAvatar}
                                icon={
                                    props.notification.type ===
                                    UserNotificationType.INTAKE_FORM
                                        ? faMemoCircleCheck
                                        : props.notification.type ===
                                          UserNotificationType.BOOKING_REQUEST
                                        ? faBellConcierge
                                        : faCalendarAlt
                                }
                                subIcon={subIcon}
                                subIconClassName={classes.subIcon}
                                subIconColor={
                                    [
                                        UserNotificationSubtype.CONFIRMED,
                                        undefined
                                    ].includes(props.notification.subtype)
                                        ? '#51A372'
                                        : '#EF4F57'
                                }
                                color="#000000"
                                bgColor="#FAEFDF"
                                size={58}
                            ></IconAvatar>
                        </Grid>
                    </Grid>
                    <Grid item xs={9} className={classes.notificationMeta}>
                        <Grid container>
                            <Typography className={classes.notificationHeader}>
                                {(props.notification.sentAt
                                    ? props.notification.sentAt
                                    : props.notification.createdAt
                                ).format('DD-MM-YYYY') ===
                                moment().tz(timeZone).format('DD-MM-YYYY')
                                    ? (props.notification.sentAt
                                          ? props.notification.sentAt
                                          : props.notification.createdAt
                                      ).fromNow()
                                    : (props.notification.sentAt
                                          ? props.notification.sentAt
                                          : props.notification.createdAt
                                      ).format('MMM DD, YYYY')}{' '}
                                •{' '}
                                {props.notification.type ===
                                UserNotificationType.BOOKING
                                    ? 'Bookings'
                                    : props.notification.type ===
                                      UserNotificationType.BOOKING_REQUEST
                                    ? 'Online Booking'
                                    : props.notification.type ===
                                      UserNotificationType.INTAKE_FORM
                                    ? 'Clients'
                                    : 'None'}
                            </Typography>
                        </Grid>

                        <Grid container>
                            <QuillContent
                                className={classes.notificationTitle}
                                content={props.notification.subject as string}
                            />
                        </Grid>

                        <Grid container>
                            <QuillContent
                                className={classes.notificationDetail}
                                content={props.notification.body as string}
                            />
                        </Grid>

                        {shouldShowActions && (
                            <Grid container>
                                <NotificationRowActions
                                    loading={isLoading}
                                    notification={props.notification}
                                    onStatusChange={() => setIsLoading(true)}
                                    onStatusChanged={() => {
                                        setIsLoading(true);
                                        getBooking();
                                    }}
                                />
                            </Grid>
                        )}

                        {shouldShowBookingStatus && (
                            <Grid container>
                                <NotificationRowRequestStatus
                                    bookingStatus={
                                        bookingStatus ||
                                        props.notification.bookingStatus
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={1}>
                        {!props.notification.openedAt && (
                            <Box className={classes.dot}></Box>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default NotificationRow;
